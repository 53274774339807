import React, { useContext } from 'react';
import { TbTrash } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { displayMoney } from '../../helpers/utils';
import cartContext from '../../contexts/cart/cartContext';
import QuantityBox from '../common/QuantityBox';
import styled from "styled-components";


const CartItem = (props) => {

    const { id, images, title, info, finalPrice, originalPrice, quantity, path, quantityBox, optPrice } = props;

    const { removeItem } = useContext(cartContext);

    const newPrice = displayMoney(finalPrice);
    const oldPrice = displayMoney(originalPrice);
    console.log(quantityBox, quantity)


    return (
        <>
            <div className="cart_item">
                <figure className="cart_item_img">
                    <Link to={`${path}${id}`}>
                        <img src={images[0]} alt="product-img" />
                    </Link>
                </figure>
                <div className="cart_item_info">
                    <div className="cart_item_head">
                        <h4 className="cart_item_title">
                            <Link to={`/product-details/${id}`}>{title} {info}</Link>
                            <StyledP>(При заказе от {quantityBox}шт скидка 10%)</StyledP>
                        </h4>
                        <div className="cart_item_del">
                            <span onClick={() => removeItem(id)}>
                                <TbTrash />
                            </span>
                            <div className="tooltip">Удалить</div>
                        </div>
                    </div>

                    <h2 className="cart_item_price">
                        {/*{quantityBox}*/}
                        {quantity<quantityBox?newPrice:optPrice}
                        {/*{newPrice}*/}
                        &nbsp;
                        <small><del>{oldPrice}</del></small>
                    </h2>

                    <QuantityBox itemId={id} itemQuantity={quantity} />
                </div>
            </div>
        </>
    );
};

export default CartItem;
const StyledP = styled.p`
  padding-top: 10px;
color: green;
`