export const sortMenu = [
    {
        id: 1,
        title: "Скидки",
    },
    {
        id: 2,
        title: "Новинки",
    },
    {
        id: 3,
        title: "Топ",
    },
    {
        id: 4,
        title: "Цена(ниже)",
    },
    {
        id: 5,
        title: "Цена(выше)"
    },
];

export const brandsMenu = [
    {
        id: 1,
        label: "Bandido",
        checked: false,
    },
    {
        id: 2,
        label: "BoAt",
        checked: false,
    },
    {
        id: 3,
        label: "Sony",
        checked: false,
    },
];

export const categoryMenu = [
    {
        id: 1,
        label: "Кондиционер для волос",
        checked: false,
    },
    {
        id: 2,
        label: "Средства для укладки волос",
        checked: false,
    },
    {
        id: 3,
        label: "Гель для бритья",
        checked: false,
    },
    {
        id: 4,
        label: "Крем после бритья",
        checked: false,
    },
    {
        id: 5,
        label: "Лосьон после бритья",
        checked: false,
    },
    {
        id: 6,
        label: "Средства для ухода за бородой",
        checked: false,
    },
    {
        id: 7,
        label: "Шампунь",
        checked: false,
    },
    {
        id: 8,
        label: "Тоник для волос",
        checked: false,
    },

];
