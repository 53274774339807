import {PiTelegramLogo} from "react-icons/pi";
import {AiOutlinePhone, AiOutlineWhatsApp} from "react-icons/ai";
import {FaVk} from "react-icons/fa6";

export const footMenu = [
    {
        id: 1,
        title: "Помощь",
        menu: [
            {
                id: 1,
                link: "Варианты доставки",
                path: "https://wa.me/+79507157766"
            },
            {
                id: 2,
                link: "Правила возврата",
                path: "https://wa.me/+79507157766"
            },
            {
                id: 3,
                link: "Оплата",
                path: "https://wa.me/+79507157766"
            },
            {
                id: 4,
                link: "Для оптовиков",
                path: "https://wa.me/+79507157766"
            },
        ]
    },

    {
        id: 3,
        title: "О компании",
        menu: [
            {
                id: 1,
                link: "Как нас найти",
                path: "https://wa.me/+79507157766"
            },
            {
                id: 2,
                link: "Сотрудничество",
                path: "https://wa.me/+79507157766"
            },
        ]
    }
];

export const footSocial = [
    {
        id: 1,
        icon: <PiTelegramLogo />,
        path: "https://t.me/bandidorussia",
    },
    {
        id: 2,
        icon: <FaVk />,
        path: "https://vk.com/club225413610",
    },
    {
        id: 3,
        icon: <AiOutlineWhatsApp />,
        path: "https://wa.me/+79507157766",
    },
    {
        id: 4,
        icon: <AiOutlinePhone />,
        path: "tel: +79507157766",
    },
];
